<template>
  <div>
    <b-row class='mb-4'>
      <b-col cols='12' class='mb-2'>
        <h5>Provider Metadata</h5>
        <small>Metadata for this specific provider</small>
      </b-col>
    </b-row>
    <b-row class='mb-4'>
      <b-col cols='3'>
        <span>Last File Ingested:</span>
      </b-col>
      <b-col cols='9'>
        <span>{{ normalizeString(last_file_ingested) }}</span>
      </b-col>
    </b-row>
    <b-row class='mb-4'>
      <b-col cols='3'>
        <span>Last File Ingested At:</span>
      </b-col>
      <b-col cols='9'>
        <span>{{ normalizeDate(last_file_ingested_at) }}</span>
      </b-col>
    </b-row>
    <b-row class='mb-4'>
      <b-col cols='3'>
        <span>Number Of Lines Ingested in Last File:</span>
      </b-col>
      <b-col cols='9'>
        <span>{{ normalizeString(last_file_number_of_lines) }}</span>
      </b-col>
    </b-row>
    <router-link
      :to="{ name: 'ClientEligibilityIngestionHistory', params: { clientId: onboardingClientId }, query: { folder }}"
      variant="primary"
      class="btn btn-success"
      target="_blank"
      size="sm"
      style="min-width: 50px">
      View Ingestion History
    </router-link>
  </div>
</template>

<script>
import * as R from 'ramda';
import moment from 'moment/moment';

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default {
  name: 'IngestionMetadata',
  props: {
    metadata: {
      type: Object,
      default: () => { },
    },
    onboardingClientId: {
      type: Number,
      default: 0,
    },
    folder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      last_file_ingested: this.metadata?.last_file_ingested,
      last_file_ingested_at: this.metadata?.last_file_ingested_at,
      last_file_number_of_lines: this.metadata?.last_file_number_of_lines,
      quality_check: this.metadata?.quality_check,
    };
  },
  methods: {
    normalizeDate(date) {
      if (isNullOrEmpty(date)) {
        return 'No file ingested yet';
      }
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    normalizeString(field) {
      if (isNullOrEmpty(field)) {
        return 'No file ingested yet';
      }
      return field.toString();
    },
  },
};
</script>

<style></style>
