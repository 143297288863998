<template>
  <div>
    <b-card bg-variant="light" no-body>
      <div class="d-flex mx-2 mt-4">
        <b-form-group
          id="input-group-provider"
          :invalid-feedback="handleError('config.provider_name')"
          :state="isValid('config.provider_name')"
          label="Provider name"
          class="flex-1"
          description="This name is just to be used internally to identify the provider/vendor of the eligibility file">
          <b-form-input
            id="eligibility-provider-name"
            v-model.trim="config.provider_name"
            placeholder="ex: Humana"
            type="text"
            :value="client.name"
            maxlength="254"/>
        </b-form-group>
        <b-form-group
          id="input-group-folder-path"
          :invalid-feedback="handleError('config.folder')"
          :state="isValid('config.folder')"
          label="File path"
          class="flex-1"
          description="The folder from which to load the client eligibility files">
          <b-form-input
            id="eligibility-file-path"
            v-model.trim="config.folder"
            placeholder="ex: /srv/ftp/CLIENT_NAME/eligibility/"
            maxlength="254"/>
        </b-form-group>
        <b-form-group
          id="input-group-separator"
          label="File separator"
          description="Separator used in the client eligibility file">
          <b-form-select
            id="eligibility-file-separator"
            v-model="config.separator"
            :options="eligibilityFileSeparatorOptions"/>
        </b-form-group>
      </div>
    </b-card>
    <!-- Subscribers -->
    <hr class="mt-4" />
    <div class="my-4">
      <h5>Subscriber fields</h5>
      <small>Specify the column numbers for each field of the subscriber</small>
    </div>
    <b-card bg-variant="light" no-body class="px-2">
      <b-row class="mt-4" no-gutters>
        <b-col cols="2">
          <b-form-group
            id="input-group-firstname"
            :invalid-feedback="handleError('config.column_number_first_name')"
            :state="isValid('config.column_number_first_name')"
            label="First name">
            <b-form-input
              v-model="config.column_number_first_name"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-lastname"
            label="Last name"
            :invalid-feedback="handleError('config.column_number_last_name')"
            :state="isValid('config.column_number_last_name')">
            <b-form-input
              v-model="config.column_number_last_name"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-dob"
            label="Date of birth"
            :invalid-feedback="handleError('config.column_number_dob')"
            :state="isValid('config.column_number_dob')">
            <b-form-input
              v-model="config.column_number_dob"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-email"
            :invalid-feedback="handleError('config.column_number_email')"
            :state="isValid('config.column_number_email')"
            label="Email">
            <b-form-input
              v-model="config.column_number_email"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-member-id"
            :invalid-feedback="handleError('config.column_number_member_id')"
            :state="isValid('config.column_number_member_id')"
            label="Member ID">
            <b-form-input
              v-model="config.column_number_member_id"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-subscriber-id"
            :invalid-feedback="handleError('config.column_number_subscriber_id')"
            :state="isValid('config.column_number_subscriber_id')"
            label="Health Insurance ID">
            <b-form-input
              v-model="config.column_number_subscriber_id"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4" no-gutters>
        <b-col cols="2">
          <b-form-group
            id="input-group-phone"
            :invalid-feedback="handleError('config.column_number_phone')"
            :state="isValid('config.column_number_phone')"
            label="Phone">
            <b-form-input
              v-model="config.column_number_phone"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-gender"
            :invalid-feedback="handleError('config.column_number_gender')"
            :state="isValid('config.column_number_gender')"
            label="Gender">
            <b-form-input
              v-model="config.column_number_gender"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-address"
            :invalid-feedback="handleError('config.column_number_address')"
            :state="isValid('config.column_number_address')"
            label="Address">
            <b-form-input
              v-model="config.column_number_address"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-adress2"
            label="Address 2"
            :invalid-feedback="handleError('config.column_number_address2')"
            :state="isValid('config.column_number_address2')">
            <b-form-input
              v-model="config.column_number_address2"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-city"
            label="City"
            :invalid-feedback="handleError('config.column_number_city')"
            :state="isValid('config.column_number_city')">
            <b-form-input
              v-model="config.column_number_city"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-state"
            :invalid-feedback="handleError('config.column_number_state')"
            :state="isValid('config.column_number_state')"
            label="State">
            <b-form-input
              v-model="config.column_number_state"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4" no-gutters>
        <b-col cols="2">
          <b-form-group
            id="input-group-zip-code"
            :invalid-feedback="handleError('config.column_number_zip_code')"
            :state="isValid('config.column_number_zip_code')"
            label="Zip Code">
            <b-form-input
              v-model="config.column_number_zip_code"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
           <b-form-group
            label="Country"
            :invalid-feedback="handleError('config.column_number_country')"
            :state="isValid('config.column_number_country')">
            <b-form-input
              v-model="config.column_number_country"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-ssn"
            :invalid-feedback="handleError('config.column_number_ssn')"
            :state="isValid('config.column_number_ssn')"
            label="SSN">
            <b-form-input
              v-model="config.column_number_ssn"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-policy-name"
            :invalid-feedback="handleError('config.column_number_group_policy_name')"
            :state="isValid('config.column_number_group_policy_name')"
            label="Group Policy Name">
            <b-form-input
              v-model="config.column_number_group_policy_name"
              type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            id="input-group-policy-id"
            :invalid-feedback="handleError('config.column_number_group_policy_id')"
            :state="isValid('config.column_number_group_policy_id')" label="Group Policy ID">
            <b-form-input v-model="config.column_number_group_policy_id" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group id="input-do-not-contact" :invalid-feedback="handleError('config.column_number_do_not_contact')"
            :state="isValid('config.column_number_do_not_contact')" label="Do Not Contact">
            <b-form-input v-model="config.column_number_do_not_contact" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4" no-gutters>
        <b-col cols="2">
          <b-form-group id="input-employee-start-date"
            :invalid-feedback="handleError('config.column_number_employee_start_date')"
            :state="isValid('config.column_number_employee_start_date')" label="Employee Start Date">
            <b-form-input v-model="config.column_number_employee_start_date" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group id="input-employee-term-date"
            :invalid-feedback="handleError('config.column_number_employee_term_date')"
            :state="isValid('config.column_number_employee_term_date')" label="Employee Term Date">
            <b-form-input v-model="config.column_number_employee_term_date" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group id="input-health-coverage"
            :invalid-feedback="handleError('config.column_number_health_coverage')"
            :state="isValid('config.column_number_health_coverage')" label="Health Coverage">
            <b-form-input v-model="config.column_number_health_coverage" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group id="input-health-coverage-start-date"
            :invalid-feedback="handleError('config.column_number_health_coverage_start_date')"
            :state="isValid('config.column_number_health_coverage_start_date')" label="Health Coverage Start Date">
            <b-form-input v-model="config.column_number_health_coverage_start_date" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group id="input-health-coverage-end-date"
            :invalid-feedback="handleError('config.column_number_health_coverage_end_date')"
            :state="isValid('config.column_number_health_coverage_end_date')" label="Health Coverage End Date">
            <b-form-input v-model="config.column_number_health_coverage_end_date" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group id="input-employee-business-unit"
            :invalid-feedback="handleError('config.column_number_employer_business_unit')"
            :state="isValid('config.column_number_employer_business_unit')" label="Employer Business Unit">
            <b-form-input v-model="config.column_number_employer_business_unit" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4" no-gutters>
        <b-col cols="2">
          <b-form-group id="input-plan-type" :invalid-feedback="handleError('config.column_number_plan_type')"
            :state="isValid('config.column_number_plan_type')" label="Plan Type">
            <b-form-input v-model="config.column_number_plan_type" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group id="input-payer" :invalid-feedback="handleError('config.column_number_payer')"
            :state="isValid('config.column_number_payer')" label="Payer">
            <b-form-input v-model="config.column_number_payer" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
        <b-col cols="2" v-if="config.dependent_mapping_option === 0">
          <b-form-group :invalid-feedback="handleError('config.column_number_dependent_relationship')"
            :state="isValid('config.column_number_dependent_relationship')" label="Relationship">
            <b-form-input v-model="config.column_number_dependent_relationship" required type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group id="input-service-type" :invalid-feedback="handleError('config.column_number_service_type')"
            :state="isValid('config.column_number_service_type')" label="Service Type">
            <b-form-input v-model="config.column_number_service_type" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
          label="Line of Business"
          id="input-line-of-business"
          :invalid-feedback="handleError('config.column_number_line_of_business')"
          :state="isValid('config.column_number_line_of_business')">
            <b-form-input v-model="config.column_number_line_of_business" type="number"
              oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- Dependents -->
    <hr class="mt-4" />
    <h5>Dependents</h5>
    <b-card bg-variant="light" class="mt-4">
      <b-form-group label="Dependent Mapping" class="m-0 p-0">
        <b-form-radio v-model="config.dependent_mapping_option" :value="0">No dependents or non-relatable members</b-form-radio>
        <b-form-radio v-model="config.dependent_mapping_option" :value="1">Same row as the subscriber</b-form-radio>
        <b-form-radio v-model="config.dependent_mapping_option" :value="2">Different row by Aggregate ID</b-form-radio>
      </b-form-group>
      <div v-if="config.dependent_mapping_option === 1">
        <small>Specify the column numbers for each one of the dependents.
          Each field supports multiple column numbers - use CSV for multiple columns (ex: 1, 4, 7).
        </small>
        <b-row class="mt-2">
          <b-col cols="2" class="mb-2">
            <b-form-group
              label="Relationship"
              :invalid-feedback="handleError('config.column_number_dependent_relationship')"
              :state="isValid('config.column_number_dependent_relationship')">
              <b-form-input
                id="input-dependent-relationship"
                v-model.trim="config.column_number_dependent_relationship"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="2">
            <b-form-group
              label="First name"
              :invalid-feedback="handleError('config.column_number_dependent_first_name')"
              :state="isValid('config.column_number_dependent_first_name')">
              <b-form-input
                id="input-dependent-first-name"
                v-model.trim="config.column_number_dependent_first_name"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Last name"
              :invalid-feedback="handleError('config.column_number_dependent_last_name')"
              :state="isValid('config.column_number_dependent_last_name')">
              <b-form-input
                id="input-dependent-last-name"
                v-model.trim="config.column_number_dependent_last_name"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Date of birth"
              :invalid-feedback="handleError('config.column_number_dependent_dob')"
              :state="isValid('config.column_number_dependent_dob')">
              <b-form-input
                id="input-dependent-dob"
                v-model.trim="config.column_number_dependent_dob"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Email"
              :invalid-feedback="handleError('config.column_number_dependent_email')"
              :state="isValid('config.column_number_dependent_email')">
              <b-form-input
                id="input-dependent-email"
                v-model.trim="config.column_number_dependent_email"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Phone"
              :invalid-feedback="handleError('config.column_number_dependent_phone')"
              :state="isValid('config.column_number_dependent_phone')">
              <b-form-input
                id="input-dependent-phone"
                v-model.trim="config.column_number_dependent_phone"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Gender"
              :invalid-feedback="handleError('config.column_number_dependent_gender')"
              :state="isValid('config.column_number_dependent_gender')">
              <b-form-input
                id="input-dependent-gender"
                v-model.trim="config.column_number_dependent_gender"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="2">
            <b-form-group
              label="Address"
              :invalid-feedback="handleError('config.column_number_dependent_address')"
              :state="isValid('config.column_number_dependent_address')">
              <b-form-input
                id="input-dependent-address"
                v-model.trim="config.column_number_dependent_address"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Address 2"
              :invalid-feedback="handleError('config.column_number_dependent_address2')"
              :state="isValid('config.column_number_dependent_address2')">
              <b-form-input
                id="input-dependent-address2"
                v-model.trim="config.column_number_dependent_address2"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="City"
              :invalid-feedback="handleError('config.column_number_dependent_city')"
              :state="isValid('config.column_number_dependent_city')">
              <b-form-input
                id="input-dependent-city"
                v-model.trim="config.column_number_dependent_city"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="State"
              :invalid-feedback="handleError('config.column_number_dependent_state')"
              :state="isValid('config.column_number_dependent_state')">
              <b-form-input
                id="input-dependent-state"
                v-model.trim="config.column_number_dependent_state"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Country"
              :invalid-feedback="handleError('config.column_number_dependent_country')"
              :state="isValid('config.column_number_dependent_country')">
              <b-form-input
                id="input-dependent-country"
                v-model.trim="config.column_number_dependent_country"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Zip Code"
              :invalid-feedback="handleError('config.column_number_dependent_zip_code')"
              :state="isValid('config.column_number_dependent_zip_code')">
              <b-form-input
                id="input-dependent-zip_code"
                v-model.trim="config.column_number_dependent_zip_code"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="2">
            <b-form-group
              label="Member ID"
              :invalid-feedback="handleError('config.column_number_dependent_member_id')"
              :state="isValid('config.column_number_dependent_member_id')">
              <b-form-input
                id="input-dependent-member-id"
                v-model.trim="config.column_number_dependent_member_id"
                type="text"
                oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="SSN"
              :invalid-feedback="handleError('config.column_number_dependent_ssn')"
              :state="isValid('config.column_number_dependent_ssn')">
              <b-form-input id="input-dependent-ssn"
                            v-model.trim="config.column_number_dependent_ssn"
                            type="text"
                            oninput="javascript:if(this.value.length>8) this.value=this.value.slice(0,8);" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="input-dependent-do-not-contact"
              :invalid-feedback="handleError('config.column_number_dependent_do_not_contact')"
              :state="isValid('config.column_number_dependent_do_not_contact')" label="Do Not Contact">
              <b-form-input v-model="config.column_number_dependent_do_not_contact" type="number"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="input-dependent-employee-start-date"
              :invalid-feedback="handleError('config.column_number_dependent_employee_start_date')"
              :state="isValid('config.column_number_dependent_employee_start_date')" label="Employee Start Date">
              <b-form-input v-model="config.column_number_dependent_employee_start_date" type="number"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="input-dependent-employee-term-date"
              :invalid-feedback="handleError('config.column_number_dependent_employee_term_date')"
              :state="isValid('config.column_number_dependent_employee_term_date')" label="Employee Term Date">
              <b-form-input v-model="config.column_number_dependent_employee_term_date" type="number"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="input-dependent-health-coverage"
              :invalid-feedback="handleError('config.column_number_dependent_health_coverage')"
              :state="isValid('config.column_number_dependent_health_coverage')" label="Health Coverage">
              <b-form-input v-model="config.column_number_dependent_health_coverage" type="number"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="2">
            <b-form-group id="input-dependent-health-coverage-start-date"
              :invalid-feedback="handleError('config.column_number_dependent_health_coverage_start_date')"
              :state="isValid('config.column_number_dependent_health_coverage_start_date')"
              label="Health Coverage Start Date">
              <b-form-input v-model="config.column_number_dependent_health_coverage_start_date" type="number"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="input-dependent-health-coverage-end-date"
              :invalid-feedback="handleError('config.column_number_dependent_health_coverage_end_date')"
              :state="isValid('config.column_number_dependent_health_coverage_end_date')"
              label="Health Coverage End Date">
              <b-form-input v-model="config.column_number_dependent_health_coverage_end_date" type="number"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="input-dependent-employee-business-unit"
              :invalid-feedback="handleError('config.column_number_dependent_employer_business_unit')"
              :state="isValid('config.column_number_dependent_employer_business_unit')" label="Employer Business Unit">
              <b-form-input v-model="config.column_number_dependent_employer_business_unit" type="number"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="input-dependent-plan-type"
              :invalid-feedback="handleError('config.column_number_dependent_plan_type')"
              :state="isValid('config.column_number_dependent_plan_type')" label="Plan Type">
              <b-form-input v-model="config.column_number_dependent_plan_type" type="number"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group id="input-dependent-payer"
              :invalid-feedback="handleError('config.column_number_dependent_payer')"
              :state="isValid('config.column_number_dependent_payer')" label="Payer">
              <b-form-input v-model="config.column_number_dependent_payer" type="number"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);" />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="row mt-4" v-else-if="config.dependent_mapping_option === 2">
        <b-form-group
          class="col-2"
          :invalid-feedback="handleError('config.dependent_mapping_column_number')"
          :state="isValid('config.dependent_mapping_column_number')"
          label="Aggregate ID">
          <b-form-input
            v-model="config.dependent_mapping_column_number"
            type="number"
            oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
        </b-form-group>
        <b-form-group
          class="col-2"
          :invalid-feedback="handleError('config.column_number_dependent_relationship_aggregate')"
          :state="isValid('config.column_number_dependent_relationship_aggregate')"
          label="Relationship">
          <b-form-input
            v-model="config.column_number_dependent_relationship_aggregate"
            required
            type="number"
            oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
        </b-form-group>
      </div>

      <!-- Relationship mapping -->
      <b-row class="mt-4">
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5>Relationship mapping</h5>
              <small>Create a eligibility relationship based on key/value</small>
            </b-col>
          </b-row>
          <b-form-group
            v-if="invalidDependentMapping"
            id="gp_invalid_swordprogram"
            label="At least one entry for the Key ‘Self’ or ‘Employee’ is required to save the config"
            class="red_warning"/>
          <b-row v-for="(relationship, idx) in $v.config.relationship.$each.$iter" :key="idx" class="mb-2">
            <b-col>
              <b-form-group
                label="Key"
                description="Key value from eligibility file"
                :state="!relationship.key.$invalid"
                invalid-feedback="Relationship mapping requires Key">
                <b-form-select
                  v-model="relationship.key.$model"
                  class="relationship_mapping_key"
                  :options="eligibilityRelationshipOptions"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Value"
                description="Translate relationship key to value"
                :state="!relationship.value.$invalid"
                :invalid-feedback="mappingError('Relationship', relationship.value)">
                <b-form-input
                  v-model.trim="relationship.value.$model"
                  type="text"
                  class="relationship_mapping_value"/>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="d-flex justify-content-center align-items-center">
              <b-button variant="light" @click="deleteMappingEntry(idx, config.relationship)">
                <feather type="trash"/>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12">
              <b-button
                class="btn_addRelationship"
                variant="info"
                @click="addMappingEntry(config.relationship)">
                Add relationship mapping
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <!-- SWORD Programs -->
    <b-row class="mt-4">
      <b-col cols="12">
        <hr/>
      </b-col>
      <b-col cols="12" class="mb-2">
        <h5>SWORD Programs</h5>
        <small>
          Configuration for members eligibility per SWORD Program.
          In case checkbox is ticked the respective column configuration will be disabled and vice versa
        </small>
        <div class="div_invalid_swordprogram_section">
          <b-form-group
            v-if="invalidSwordProgram"
            id="gp_invalid_swordprogram_section"
            :label="invalidSwordProgramLabel"
            class="red_warning" />
        </div>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox
            id="physical_therapy"
            v-model="config.physical_therapy"
            name="physical_therapy">
          Physical Therapy (PT)
          <small class="SP_textbellow">If Checked all members will be eligible for Physical therapy program</small>
        </b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox
            id="on_call"
            v-model="config.on_call"
            name="on_call">
          On Call
          <small class="SP_textbellow">If Checked all members will be eligible for On Call program</small>
        </b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox
            id="academy"
            v-model="config.academy"
            name="academy">
          Academy
          <small class="SP_textbellow">If Checked all members will be eligible for Academy program</small>
        </b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox
            id="dpt_go"
            v-model="config.dpt_go"
            name="dpt_go">
          DPT Go
          <small class="SP_textbellow">If Checked all members will be eligible for DPT Go program</small>
        </b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox
            id="move"
            v-model="config.move"
            name="move">
          Move
          <small class="SP_textbellow">If Checked all members will be eligible for Move program</small>
        </b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox
            id="bloom"
            v-model="config.bloom"
            name="bloom">
          Bloom
          <small class="SP_textbellow">If Checked all members will be eligible for Bloom program</small>
        </b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <b-form-checkbox
            id="mind"
            v-model="config.mind"
            name="mind">
          Mind
          <small class="SP_textbellow">If Checked all members will be eligible for Mind program</small>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <br/>
        <small>Specify the column numbers for each field of the SWORD programs
          in case client is sending the columns on their eligibility file.</small>
        <br/><br/>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="3">
        <b-form-group
          id="input-group-pt"
          label="Physical Therapy (PT)"
          :invalid-feedback="handleError('config.column_number_physical_therapy')"
          :state="isValid('config.column_number_physical_therapy')">
          <b-form-input
            id="input-swordPrograms-pt"
            v-model="config.column_number_physical_therapy"
            type="number"
            :disabled="config.physical_therapy"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="On Call"
          :invalid-feedback="handleError('config.column_number_on_call')"
          :state="isValid('config.column_number_on_call')">
          <b-form-input
            id="input-swordPrograms-on_call"
            v-model="config.column_number_on_call"
            type="number"
            :disabled="config.on_call"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="Academy"
          :invalid-feedback="handleError('config.column_number_academy')"
          :state="isValid('config.column_number_academy')">
          <b-form-input
            id="input-swordPrograms-academy"
            v-model="config.column_number_academy"
            type="number"
            :disabled="config.academy"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="DPT Go"
          :invalid-feedback="handleError('config.column_number_dpt_go')"
          :state="isValid('config.column_number_dpt_go')">
          <b-form-input
            id="input-swordPrograms-dpt_go"
            v-model="config.column_number_dpt_go"
            type="number"
            :disabled="config.dpt_go"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="Move"
          :invalid-feedback="handleError('config.column_number_move')"
          :state="isValid('config.column_number_move')">
          <b-form-input
            id="input-swordPrograms-move"
            v-model="config.column_number_move"
            type="number"
            :disabled="config.move"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="Bloom"
          :invalid-feedback="handleError('config.column_number_bloom')"
          :state="isValid('config.column_number_bloom')">
          <b-form-input
            id="input-swordPrograms-bloom"
            v-model="config.column_number_bloom"
            type="number"
            :disabled="config.bloom"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="Mind"
          :invalid-feedback="handleError('config.column_number_mind')"
          :state="isValid('config.column_number_mind')">
          <b-form-input
            id="input-swordPrograms-mind"
            v-model="config.column_number_mind"
            type="number"
            :disabled="config.mind"/>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Ecosystem -->
    <hr class="my-4" />
    <div class="mb-2">
      <h5>Ecosystems</h5>
      <small>Configuration for member ecosystem external identifier</small>
    </div>
    <b-card bg-variant="light">
      <b-row>
        <b-col cols="3">
          <b-form-group
            class="p-0 m-0"
            label="External identifier"
            :invalid-feedback="handleError('config.column_number_ecosystem_external_id')"
            :state="isValid('config.column_number_ecosystem_external_id')">
            <b-form-input
              id="input-ecosystem-external-id"
              v-model="config.column_number_ecosystem_external_id"
              type="number"/>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- Multi-tenant eligibility file-->
    <b-row class="mt-4">
      <b-col cols="12">
        <hr />
      </b-col>
      <b-col>
        <b-row class="mb-4">
          <b-col cols="12" class="mb-2">
            <h5>Multi-tenant eligibility file</h5>
            <small>When multi-tenant is enabled, you need to specify which column the file is</small>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="6">
            <b-form-group
              id="input-group-2"
              :invalid-feedback="handleError('config.column_number_aso_group')"
              :state="isValid('config.column_number_aso_group')"
              :disabled="!multitenantActive"
              label="Eligibility ASO Group column:"
              description="Specify the column number where the ASO group appears in the eligibility file">
              <b-form-input
                id="multi-tenant-aso-group"
                v-model.trim="config.column_number_aso_group"
                placeholder=""
                :disabled="!multitenantActive"
                oninput="javascript:if(this.value.length>2) this.value=this.value.slice(0,2);"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- External ids-->
    <b-row class="mt-4">
      <b-col cols="12">
        <hr />
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5>External IDs</h5>
            <small>Configuration to extract external ids from eligibility file</small>
          </b-col>
        </b-row>
        <b-row v-for="(externalId, idx) in config.external_ids" :key="idx" class="mb-2">
          <b-col>
            <b-form-group label="Label" description="Label for this external id field">
              <b-form-input v-model.trim="externalId.label" type="text" maxlength="254" class="externalId_label"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Column number"
              description="Column number from which to fetch the external id value">
              <b-form-input
                v-model="externalId.column_number"
                type="number"
                oninput="javascript:if(this.value.length>3) this.value=this.value.slice(0,3);"/>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="d-flex justify-content-center align-items-center">
            <b-button variant="light" @click="config.external_ids.splice(idx, 1)">
              <feather type="trash"/>
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="config.external_ids && config.external_ids.length < 3" class="mt-3">
          <b-col cols="12">
            <b-button class="btn_addExternalId" variant="info" @click="addExternalId(config)">Add external ID mapping</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Gender mapping -->
    <b-row class="mt-4">
      <b-col cols="12">
        <hr />
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5>Gender mapping</h5>
            <small>Create a gender map based on a key/value pair</small>
          </b-col>
        </b-row>
        <b-row v-for="(gender, idx) in $v.config.gender.$each.$iter" :key="idx" class="mb-2">
          <b-col>
            <b-form-group
              label="Key"
              description="Key value from eligibility file"
              :state="!gender.key.$invalid"
              invalid-feedback="Gender mapping requires Key">
              <b-form-select
                v-model="gender.key.$model"
                class="gender_mapping_key"
                :options="eligibilityGenderOptions"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Value"
              description="Translate gender key to value"
              :state="!gender.value.$invalid"
              :invalid-feedback="mappingError('Gender', gender.value)">
              <b-form-input
                v-model.trim="gender.value.$model"
                type="text"
                class="gender_mapping_value"/>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="d-flex justify-content-center align-items-center">
            <b-button variant="light" @click="deleteMappingEntry(idx, config.gender)">
              <feather type="trash"/>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12">
            <b-button class="btn_addGender" variant="info" @click="addMappingEntry(config.gender)">Add gender mapping</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Payers mapping -->
    <b-row class="mt-4 payers_mapping">
      <b-col cols="12">
        <hr />
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5>Payers mapping</h5>
            <small>Create a payer map based on a key/value pair</small>
          </b-col>
        </b-row>
        <b-row v-for="(payer, idx) in $v.config.payers.$each.$iter" :key="idx" class="mb-2">
          <b-col cols="2">
            <b-form-group
              label="Use payer for the full file"
              :state="!payer.for_all.$invalid"
              invalid-feedback="Only one mapping is allowed when selecting this option"
              class="text-center">
              <b-form-checkbox
                v-model="payer.for_all.$model"
                size="lg"
                :disabled="$v.config.payers.$model.length>1"
                @input="payer.value.$model=''"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Key"
              description="Key value from eligibility file"
              :state="!payer.payer_id.$invalid"
              invalid-feedback="Payer mapping requires Key">
              <b-form-select
                v-model="payer.payer_id.$model"
                :options="payerLabels"/>
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group
              label="Value"
              description="Translate payer key to value"
              :state="!payer.value.$invalid"
              :invalid-feedback="mappingError('Value', payer.value)">
              <b-form-input
                v-model.trim="payer.value.$model"
                :disabled="payer.for_all.$model"
                type="text"/>
            </b-form-group>
          </b-col>
          <b-col class="d-flex justify-content-center align-items-center">
            <b-button variant="light" @click="deleteMappingEntry(idx, config.payers)">
              <feather type="trash"/>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12">
            <b-button
              variant="info"
              :disabled="!canAddMorePayerMappings()"
              @click="addPayerMappingEntry()">Add payer mapping</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Service Type mapping -->
    <b-row class="mt-4">
      <b-col cols="12">
        <hr />
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5>Service Type mapping</h5>
            <small>Create a service type map based on a key/value pair</small>
          </b-col>
        </b-row>
        <b-row v-for="(service_type, idx) in $v.config.service_type.$each.$iter" :key="idx" class="mb-2">
          <b-col>
            <b-form-group
              label="Key"
              description="Key value from eligibility file"
              :state="!service_type.key.$invalid"
              invalid-feedback="Service Type mapping requires Key">
              <b-form-select
                v-model="service_type.key.$model"
                class="service_type_mapping_key"
                :options="eligibilityServiceTypeOptions"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Value"
              description="Translate service type key to value"
              :state="!service_type.value.$invalid"
              :invalid-feedback="mappingError('Service Type', service_type.value)">
              <b-form-input
                v-model.trim="service_type.value.$model"
                type="text"
                class="service_type_mapping_value"/>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="d-flex justify-content-center align-items-center">
            <b-button variant="light" @click="deleteMappingEntry(idx, config.service_type)">
              <feather type="trash"/>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12">
            <b-button class="btn_addServiceType" variant="info" @click="addMappingEntry(config.service_type)">Add service type mapping</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Line of Business mapping -->
    <b-row class="mt-4">
      <b-col cols="12">
        <hr />
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5>Line of Business mapping</h5>
            <small>Create a line of business map based on a key/value pair</small>
          </b-col>
        </b-row>
        <b-row v-for="(lob, idx) in $v.config.line_of_businesses.$each.$iter" :key="idx" class="mb-2">
          <b-col>
            <b-form-group
              label="Key"
              description="Key value from eligibility file"
              :state="!lob.line_of_business_id.$invalid"
              invalid-feedback="Line of Business mapping requires Key">
              <b-form-select
                data-testid="line-of-business-key"
                v-model="lob.line_of_business_id.$model"
                :options="lobOptions"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Regex"
              description="Translate line of business key to regex"
              :state="!lob.value.$invalid"
              :invalid-feedback="mappingError('Line of Business', lob.value)">
              <b-form-input
                data-testid="line-of-business-value"
                v-model.trim="lob.value.$model"
                type="text"/>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="d-flex justify-content-center align-items-center">
            <b-button variant="light" @click="deleteMappingEntry(idx, config.line_of_businesses)">
              <feather type="trash"/>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12">
            <b-button class="btn_addServiceType" variant="info" @click="addLOBMappingEntry()">
              Add line of business mapping
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Metadata -->
    <b-row class='mt-4'>
      <b-col cols='12'>
        <hr/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <IngestionMetadata :metadata="ingestionMetadata" :folder="config.folder" :onboarding-client-id="getOnboardingClientId()"/>
      </b-col>
    </b-row>
    <b-row class='mt-2'>
      <b-col cols='12'>
        <hr />
      </b-col>
    </b-row>
    <b-row class='mb-4'>
      <b-col cols='12' class='mb-2'>
        <div class="d-flex align-items-center">
          <h5>Quality Check Report</h5>
          <b-button v-if="quality_check" v-b-toggle.quality-check-report
                    @click="handleQualityCheckReportOpen" class="ml-2 mb-1 btn btn-info btn-sm">
            {{ qualityCheckReportOpen ? 'Hide' : 'Show' }} Report
          </b-button>
        </div>
        <small>Report for the last ingested file</small>
      </b-col>
    </b-row>
    <b-collapse id="quality-check-report" v-if="quality_check">
      <b-row>
        <b-col cols='12' class='mb-2'>
          <QualityCheck :data="quality_check"></QualityCheck>
        </b-col>
      </b-row>
    </b-collapse>
    <div v-else>
      <span>No report available</span>
    </div>
    <b-row class='mt-4' v-if="getOnboardingClientId()">
      <b-col cols='12'>
        <hr />
      </b-col>
      <b-col>
        <b-row class='mb-4'>
          <b-col cols='12' class='mb-2'>
            <h4>Quarantine</h4>
            <small>Eligibility Quarantine configuration and report</small>
          </b-col>
        </b-row>
        <Quarantine
          :onboardingClientId="getOnboardingClientId()"
          @quarantine-config-update="handleQuarantineConfigUpdate"
          :filepath='config.folder'/>
      </b-col>
    </b-row>
    <ClientFilePreview :config="config" :quarantineConfig="quarantineConfig" :payers="payers"></ClientFilePreview>
  </div>
</template>

<script>
import { parseValidationsStr } from '@sword-health/input-validation';
import {
  required, requiredIf, and, minValue,
} from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import {
  beneficiaryTypes,
  eligibilityFileSeparatorOptions,
  eligibilityRelationshipOptions,
  eligibilityGenderOptions,
  eligibilityServiceTypeOptions,
} from '@/scripts/definitions';
import ClientFilePreview from '@/components/SFTP/Client/DryRun/FilePreview.vue';
import IngestionMetadata from '@/views/Onboarding/Eligibility/IngestionMetadata.vue';
import Quarantine from '@/views/Onboarding/Quarantine/Eligibility/Index.vue';
import service from '@/services/eligibility-service';
import { parseResponseError } from '@/http/parsers/parse_response';
import translations from '@/translations';
import QualityCheck from '@/views/Onboarding/Eligibility/QualityCheck.vue';

const pathRegex = new RegExp('^/([a-zA-Z]:)?([a-zA-Z0-9_.-]+/){2,}$');
const numberCSVRegex = new RegExp('^[1-9][0-9]*(,[1-9][0-9]*)*$');
const providerNameRegex = new RegExp('^[a-zA-Z0-9 ]+$');

export default {
  name: 'ClientEligibilityFileConfig',
  components: {
    QualityCheck,
    ClientFilePreview,
    IngestionMetadata,
    Quarantine,
  },
  mixins: [ errorHandler ],
  props: {
    value: {
      type: Object,
      default: () => { },
    },
    client: {
      type: Object,
      default: () => { },
    },
    multitenantActive: Boolean,
    fileConfigs: {
      type: Array,
      default: () => [],
    },
    payers: {
      type: Array,
      default: () => [],
    },
  },
  async beforeMount() {
    await this.loadQualityCheckReport();
  },
  data() {
    return {
      translations: translations.client_eligibility,
      lineOfBusinesses: [],
      config: this.value,
      quarantineConfig: null,
      eligibilityFileSeparatorOptions,
      eligibilityRelationshipOptions,
      eligibilityGenderOptions,
      eligibilityServiceTypeOptions,
      beneficiaryOptions: beneficiaryTypes.map(o => ({ value: o.value, text: o.label, disabled: o.disabled })),
      ingestionMetadata: {
        last_file_ingested: this.value?.last_file_ingested,
        last_file_ingested_at: this.value?.last_file_ingested_at,
        last_file_number_of_lines: this.value?.last_file_number_of_lines,
      },
      qualityCheckReportOpen: false,
      quality_check: null,
    };
  },
  validations() {
    return this.rules;
  },
  computed: {
    rules() {
      let cfg = {};

      if (this.config) {
        const numberCsvValidator = fieldValue => {
          if (!fieldValue || fieldValue.length === 0) return true;
          return numberCSVRegex.test(fieldValue);
        };

        const uniqueProviderNameValidator = fieldValue => {
          for (let i = 0; i < this.fileConfigs.length; i++) {
            const c = this.fileConfigs[i];

            if (c.provider_name === fieldValue) {
              if (this.config.id) {
                if (c.id !== this.config.id) return false;
              } else {
                return false;
              }
            }
          }

          return true;
        };

        const uniquePathValidator = fieldValue => {
          for (let i = 0; i < this.fileConfigs.length; i++) {
            const c = this.fileConfigs[i];

            if (c.folder === fieldValue) {
              if (this.config.id) {
                if (c.id !== this.config.id) return false;
              } else {
                return false;
              }
            }
          }

          return true;
        };

        cfg = {
          config: {
            relationship: {
              $each: {
                key: {
                  required,
                },
                value: {
                  required,
                  isUnique(value) {
                    if (value === '') {
                      return true;
                    }

                    return this.config.relationship.filter(o => o.value === value).length <= 1;
                  },
                },
              },
            },
            gender: {
              $each: {
                key: {
                  required,
                },
                value: {
                  required,
                  isUnique(value) {
                    if (value === '') {
                      return true;
                    }

                    return this.config.gender.filter(o => o.value === value).length <= 1;
                  },
                },
              },
            },
            payers: {
              $each: {
                for_all: {
                  max1PayerIfAnyForAll: () => {
                    const anyForAll = this.config.payers.some(o => o.for_all);
                    if (!anyForAll) {
                      return true;
                    }

                    return this.config.payers.length === 1;
                  },
                },
                payer_id: {
                  required,
                },
                value: {
                  required: requiredIf(elem => !elem.for_all),
                  isUnique(value) {
                    if (value === '') {
                      return true;
                    }

                    return this.config.payers.filter(o => o.value === value).length <= 1;
                  },
                },
              },
            },
            service_type: {
              $each: {
                key: {
                  required,
                },
                value: {
                  required,
                  isUnique(value) {
                    if (value === '') {
                      return true;
                    }

                    return this.config.service_type.filter(o => o.value === value).length <= 1;
                  },
                },
              },
            },
            line_of_businesses: {
              $each: {
                line_of_business_id: { required: and(required, minValue(1)) },
                value: {
                  required,
                  isUnique(value) {
                    if (value === '') return true;
                    return this.config.line_of_businesses.filter(o => o.value === value).length <= 1;
                  },
                },
              },
            },
            folder: {
              uniquePathValidator,
              ...parseValidationsStr({ required, validChars: pathRegex }),
            },
            provider_name: {
              uniqueProviderNameValidator,
              ...parseValidationsStr({
                required,
                maxL: 150,
                validChars: providerNameRegex,
              }),
            },

            dependent_mapping_column_number: {
              requiredIf: requiredIf(elem => elem.dependent_mapping_option === 2),
            },
            column_number_first_name: parseValidationsStr({ required, minLimit: 1 }),
            column_number_last_name: parseValidationsStr({ required, minLimit: 1 }),
            column_number_dob: parseValidationsStr({ required, minLimit: 1 }),
            column_number_email: parseValidationsStr({ required, minLimit: 1 }),
            column_number_member_id: parseValidationsStr({ required, minLimit: 1 }),
            column_number_subscriber_id: { numberCsvValidator },
            column_number_phone: { numberCsvValidator },
            column_number_ssn: { numberCsvValidator },
            column_number_group_policy_name: { numberCsvValidator },
            column_number_group_policy_id: { numberCsvValidator },
            column_number_gender: { numberCsvValidator },
            column_number_address: { numberCsvValidator },
            column_number_address2: { numberCsvValidator },
            column_number_city: { numberCsvValidator },
            column_number_state: { numberCsvValidator },
            column_number_country: { numberCsvValidator },
            column_number_zip_code: { numberCsvValidator },
            column_number_do_not_contact: { numberCsvValidator },
            column_number_employee_start_date: { numberCsvValidator },
            column_number_employee_term_date: { numberCsvValidator },
            column_number_health_coverage: { numberCsvValidator },
            column_number_health_coverage_start_date: { numberCsvValidator },
            column_number_health_coverage_end_date: { numberCsvValidator },
            column_number_employer_business_unit: { numberCsvValidator },
            column_number_plan_type: { numberCsvValidator },
            column_number_payer: { numberCsvValidator },
            column_number_service_type: { numberCsvValidator },
            column_number_line_of_business: { numberCsvValidator },
            column_number_dependent_first_name: { numberCsvValidator },
            column_number_dependent_last_name: { numberCsvValidator },
            column_number_dependent_dob: { numberCsvValidator },
            column_number_dependent_email: { numberCsvValidator },
            column_number_dependent_phone: { numberCsvValidator },
            column_number_dependent_relationship: { numberCsvValidator },
            column_number_dependent_relationship_aggregate: {
              requiredIf: requiredIf(elem => elem.dependent_mapping_option === 2),
            },
            column_number_dependent_gender: { numberCsvValidator },
            column_number_dependent_address: { numberCsvValidator },
            column_number_dependent_address2: { numberCsvValidator },
            column_number_dependent_city: { numberCsvValidator },
            column_number_dependent_state: { numberCsvValidator },
            column_number_dependent_country: { numberCsvValidator },
            column_number_dependent_zip_code: { numberCsvValidator },
            column_number_dependent_ssn: { numberCsvValidator },
            column_number_dependent_member_id: { numberCsvValidator },
            column_number_physical_therapy: { numberCsvValidator },
            column_number_on_call: { numberCsvValidator },
            column_number_academy: { numberCsvValidator },
            column_number_dpt_go: { numberCsvValidator },
            column_number_move: { numberCsvValidator },
            column_number_bloom: { numberCsvValidator },
            column_number_mind: { numberCsvValidator },
            column_number_aso_group: parseValidationsStr(Object.assign({},
              { maxL: 2 })),
            column_number_dependent_do_not_contact: { numberCsvValidator },
            column_number_dependent_employee_start_date: { numberCsvValidator },
            column_number_dependent_employee_term_date: { numberCsvValidator },
            column_number_dependent_health_coverage: { numberCsvValidator },
            column_number_dependent_health_coverage_start_date: { numberCsvValidator },
            column_number_dependent_health_coverage_end_date: { numberCsvValidator },
            column_number_dependent_employer_business_unit: { numberCsvValidator },
            column_number_dependent_plan_type: { numberCsvValidator },
            column_number_dependent_payer: { numberCsvValidator },
          },
        };
      }

      return cfg;
    },
    invalidDependentMapping() {
      let ok = false;
      const validRelationship = this.config.relationship && this.config.relationship.find(o => o.key === '18' || o.key === '20');
      if (this.config.dependent_mapping_option === 2 && validRelationship === undefined) {
        ok = true;
      }
      return ok;
    },
    invalidSwordProgram() {
      const { configuration } = this.client;
      const {
        physical_therapy: physicalTherapy,
        on_call: onCall,
        academy,
        bloom,
        mind,
        move,
        column_number_physical_therapy: columnNumberPhysicalTherapy,
        column_number_on_call: columnNumberOnCall,
        column_number_academy: columnNumberAcademy,
        column_number_dpt_go: columnNumberDPTGo,
        column_number_bloom: columnNumberBloom,
        column_number_mind: columnNumberMind,
        column_number_move: columnNumberMove,
      } = this.config;

      let fieldsToValidate = [ onCall, academy, columnNumberOnCall, columnNumberAcademy, columnNumberDPTGo ];

      if (configuration.dpt.enabled) {
        fieldsToValidate = fieldsToValidate.concat(physicalTherapy, columnNumberPhysicalTherapy);
      }

      if (configuration.bloom.enabled) {
        fieldsToValidate = fieldsToValidate.concat(bloom, columnNumberBloom);
      }

      if (configuration.mind?.enabled) {
        fieldsToValidate = fieldsToValidate.concat(mind, columnNumberMind);
      }

      if (configuration.move.enabled) {
        fieldsToValidate = fieldsToValidate.concat(move, columnNumberMove);
      }

      return fieldsToValidate.every(t => !t);
    },
    invalidSwordProgramLabel() {
      let programs = [ 'On Call', 'Academy' ];
      const configuration = this.client.configuration || {};

      if (configuration.dpt?.enabled) {
        programs = programs.concat('Physical Therapy');
      }

      if (configuration.bloom?.enabled) {
        programs = programs.concat('Bloom');
      }

      if (configuration.mind?.enabled) {
        programs = programs.concat('Mind');
      }

      if (configuration.move?.enabled) {
        programs = programs.concat('Move');
      }

      const language = configuration.language || 'en-US';
      const formatter = new Intl.ListFormat(language, { type: 'disjunction' });

      return `Select at least one of the following programs: ${formatter.format(programs)}`;
    },
    payerLabels() {
      const opts = [ { value: null, text: '' } ];
      this.payers.forEach(p => {
        opts.push({
          value: p.id,
          text: p.label,
        });
      });
      return opts;
    },
    lobOptions() {
      return this.lineOfBusinesses.map(lob => ({ value: lob.id, text: lob.line_of_business_name }));
    },
  },
  watch: {
    config: {
      handler(value) {
        this.triggerValidation();
        this.$emit('input', value);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getLineOfBusinesses();
    this.triggerValidation();
    if (!this.config.provider_name) this.config.provider_name = this.client.display_name;
  },
  methods: {
    loadQualityCheckReport() {
      return this.$store.dispatch('Sftp/Eligibility/getQualityCheck', {
        onboarding_client_id: this.getOnboardingClientId(),
        filepath: this.config.folder,
      }).then(({ data }) => {
        this.quality_check = data;
      }).catch(err => {
        this.$noty.error(`${err.message}`);
      });
    },
    async getLineOfBusinesses() {
      try {
        const { lobs } = await service.getLineOfBusinesses(this.client.id);
        this.lineOfBusinesses = lobs;
      } catch (err) {
        this.$noty.error(`${this.translations.line_of_business.error_get_line_of_businesses}: ${parseResponseError(err)}`);
      }
    },
    mappingError(valueType, field) {
      if (!field.required) {
        return `${valueType} mapping requires Value`;
      }
      if (!field.isUnique) {
        return `${valueType} mapping must include only unique values`;
      }

      return '';
    },
    addExternalId(config) {
      config.external_ids.push({
        label: '',
        column_number: null,
      });
    },
    addMappingEntry(map) {
      map.push({
        key: '',
        value: '',
      });
    },
    addPayerMappingEntry() {
      this.config.payers.push({
        for_all: false,
        payer_id: null,
        value: '',
      });
    },
    addLOBMappingEntry() {
      this.config.line_of_businesses.push({
        line_of_business_id: 0,
        value: '',
      });
    },
    deleteMappingEntry(idx, map) {
      map.splice(idx, 1);
    },
    triggerValidation() {
      if (this.invalidDependentMapping) {
        this.$emit('form-validation', false);
        return false;
      }
      if (!this.$v.$invalid) {
        if (!this.invalidSwordProgram) {
          this.$emit('form-validation', true);
          return true;
        }
      }

      this.$emit('form-validation', false);
      return false;
    },
    isEmpty(value) {
      return typeof value === 'undefined' || value === '';
    },
    canAddMorePayerMappings() {
      return this.config?.payers && !this.config.payers.find(o => o.for_all);
    },
    getOnboardingClientId() {
      if (this?.$route?.params?.clientId) {
        return Number(this?.$route?.params?.clientId);
      }

      return null;
    },
    handleQuarantineConfigUpdate(evt) {
      this.quarantineConfig = evt;
      this.$emit('quarantine-config-update', evt);
    },
    handleQualityCheckReportOpen() {
      this.qualityCheckReportOpen = !this.qualityCheckReportOpen;
    },
  },
};
</script>

<style scoped>
.red_warning {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.red_warning>legend {
  padding: 0 !important;
}

.div_invalid_swordprogram_section {
  height: 20px;
}

.SP_textbellow {
  margin-top: 20px;
  display: block;
  max-width: 75%;
}


</style>
